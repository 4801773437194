import React, {useState, useEffect} from 'react';
import { AboutUs, Chef, Footer, Gallery, Header, Intro, SpecialMenu } from './container';
import {FindUs} from './container/Findus/FindUs'
import { Navbar } from './components';
import './App.css';
import ScaleLoader from "react-spinners/ScaleLoader";

const App = () => {
  const [loading, setLoading] = useState(false);
   useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
   }, [])
 return (
  <div className={loading ? 'loading' : 'blank'}>
    { loading ? 
    <ScaleLoader 
    size ={30}
    color={"#d9cb8f"}
    loading={loading}
    /> :  
    <div>
     <Navbar />
     <Header />
     <AboutUs />
     <SpecialMenu />
     <Chef />
     <Intro />
     <Gallery />
     <FindUs />
     <Footer />
    </div>}
  </div>
 
 );
}
 


export default App;
