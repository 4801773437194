import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery5 from '../assets/gallery5.jpeg';
import gallery1 from '../assets/gallery1.jpeg';
import gallery6 from '../assets/gallery6.jpeg';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import logowhite from '../assets/logowhite.png';

const images = {
  bg,
  chef,
  G,
  gallery1,
  gallery02,
  gallery03,
  gallery5,
  gallery6,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  logowhite
}

export default images;
