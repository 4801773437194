

const food = [
  {
    title: 'Bruschetta con atun',
    price: '€6.5',
    tags: 'Pan tostado con atún y cebolla',
  },
  {
    title: 'Carpaccio',
    price: '€13.9',
     tags: 'Buey servido con rúcula, parmesano y salsa cipriani',
  },
  {
    title: 'Spaghetti Carbonara',
    price: '€10.9',
    tags: 'Bacon, cream and egg',
  },
  {
    title: 'Risotto di mare',
    price: '€12.9',
    tags: 'Prawns, mussels, clams ,squid',
  },
  {
    title: 'Tiramisu',
    price: '€6.5',
    // tags: 'IE | 750 ml',
  },
];
const cocktails = [
  {
    title: 'Hirosima',
    price: '€4',
    tags: 'Sambuca, Irish cream, Absinthe, Grenadine | shot',
  },
  {
    title: "Zombie",
    price: '€8',
    tags: 'Tiki Lovers Pineapple, Angostura, Almond liquor, Pineapple juice, Orange juice, Fresh lemon juice, Blue Curacao',
  },
  {
    title: 'Sunrize',
    price: '€8',
    tags: 'Piedrecita Tequila Blanco, Orange juice, Grenadine',
  },
  {
    title: 'B-52',
    price: '€4',
    tags: 'Coffe liqueur, Irish cream, Triple sec | Shot',
  },
  {
    title: 'Ice Rose',
    price: '€8',
    tags: 'Amaretto, Campari, Triple sec, Tonic water, Grenadine',
  },
];

const getData = {
  food, cocktails
}



export default getData;