import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      {/* <img src={images.G} alt="G_overlay" /> */}
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Crazy Rabbit is a family-owned business that are dedicated to creating wonderful "surprises" that you'll absolutely love. Always holding to our motto “Excellent Food, Excellent Service,” they built a loyal clientele. Part of our income we are sending back home to support the Ukrainian nation 🇺🇦.</p>
        {/* <button type="button" className="custom__button">Know More</button> */}
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Our start was very bright. The first project called Iznanka by Odessa,
          is one of the most popular bars in Ukraine.
          Our specialty is bright shows, delicious cocktails, and an unforgettable atmosphere with a stunning interior.
          All the best qualities of our projects were transferred to Crazy Rabbit.</p>
        {/* <button type="button" className="custom__button">Know More</button> */}
      </div>
    </div>
  </div>
);

export default AboutUs;
