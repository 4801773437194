import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

export const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">Pl. de la Unión Europea, 20, 29620 Torremolinos, Málaga</p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Opening Hours</p>
        <p className="p__opensans">Sun - Thr: 10:00 am - 02:00 am</p>
        <p className="p__opensans">Fri - Sat: 10:00 am - 03:00 am</p>
      </div>
      <a href='https://www.google.com/maps/dir//crazy+rabbit+torremolinos/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0xd72fbaabb69e4b5:0x91616d1f6898f0ce?sa=X&ved=2ahUKEwj_x-uN_6z6AhWVd8AKHU5hAnUQ9Rd6BAhYEAM' rel='noreferrer' target='_blank'><button type="button" className="custom__button" style={{ marginTop: '2rem' }}>Get directions</button></a>
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="finus_img" />
    </div>
  </div>
);


