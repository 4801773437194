import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';
import { Typewriter } from 'react-simple-typewriter'

const Header = () => {
  return (
    <div className="app__header app__wrapper section__padding" id="home">
      <div className="app__wrapper_info">
        <SubHeading title="Chase the new flavour" />
        <h1 className="app__header-h1">
          T<Typewriter
            words={['he Key To Perfect Mood']}
            loop={1}
            typeSpeed={90}
            deleteSpeed={50}
            delaySpeed={1500}
          /></h1>
        <p className="p__opensans" style={{ margin: '2rem 0' }}>Newly opened urban project from the famous Ukrainian restaurateurs' Vera Topaleva and Oleksandr Furman. Are serving up healthy slices of art, BDSM shows, music, chilled vibes, and excellent cocktails.</p>
        {/* <button type="button" className="custom__button">Explore Menu</button> */}
      </div>

      <div className="app__wrapper_img">
        <img src={images.welcome} alt="header_img" />
      </div>
    </div>
  )
}



export default Header;
